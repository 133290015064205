import {LoaderWithFetch} from "../components/Loader";
import React, {useContext, useEffect, useState} from "react";
import AnimatedButton from "../components/AnimatedButton";
import {PageContext} from "../PageContext";
import {useLocation, useNavigate} from "react-router-dom";
import {CheckoutPlanState} from "./CheckoutPlanPage";
import {NotifyPopup} from "../components/Popups";
import {scrollToId} from "../App";


export enum ActionType {
    new = "new",
    renew = "renew",
    cancel = "cancel",
    upgrade = "upgrade",
    downgrade = "downgrade",
    login = "login",
    none = "none",
}

interface Plan {
    id: number,
    credits: number,
    name: string,
    description: string,
    icon: string,
    price: number,
    actionType: ActionType,
    type: "byok_business" | string,
    active: boolean,
}

interface PlanData {
    plan: Plan
    creditsElement?: React.JSX.Element
}

interface CreditPurchase {
    id: number,
    credits: number,
    name: string,
    description: string,
    icon: string,
    price: number,
}

interface CreditPurchaseData {
    creditPurchase: CreditPurchase
}

interface Plans {
    plans: Plan[]
    creditPurchases: CreditPurchase[]
    credits: number
    activePlanId: number | null
    daysTillPlanRenewal?: number
    planRenewalDay?: number
    cancelled?: boolean
}

const BYOK_BUSINESS = "byok_business";

export default function PlansPage() {
    const location = useLocation()
    const [openErrorPopup, setOpenErrorPopup] = useState<boolean>(false)

    useEffect(() => {
        if (location.state?.error) {
            setOpenErrorPopup(true)
        }
    }, [location.state?.error]);

    return (
        <>
            <PlansTable includeUserData={true}/>
            <NotifyPopup open={openErrorPopup} setOpen={setOpenErrorPopup}>
                <div>
                    <h1>Error</h1>
                    <p>{location.state?.error}</p>
                </div>
            </NotifyPopup>
        </>
    )
}

export function PlansTable(props: { includeUserData: boolean }) {
    const [isTestMode, setIsTestMode] = useState<boolean>(false)
    const pageContext = useContext(PageContext)
    const isAdmin = pageContext.loggedIn && pageContext.admin
    const prefix = isTestMode ? "test/" : ""

    return (
        <div className={"gradient__07_smooth full-page-gradient"}>
            <LoaderWithFetch<Plans> url={`/api/v1/${prefix}plans`}>
                {
                    (plans) => {
                        return (
                            <>
                                {
                                    isAdmin && (
                                        <div style={{position: "absolute", right: "100px", top: "100px"}}>
                                            <AnimatedButton onClick={() => setIsTestMode(!isTestMode)}>
                                                {isTestMode ? "Test Mode" : "Live Mode"}
                                            </AnimatedButton>
                                        </div>
                                    )
                                }
                                <div className="section">
                                    {
                                        props.includeUserData && (
                                            <PlanUserData plans={plans}/>
                                        )
                                    }
                                    <div className={"section"}>
                                        <PlanList plans={plans.plans} cancelled={plans.cancelled === true}
                                                  testMode={isTestMode}/>
                                    </div>
                                    {
                                        props.includeUserData && (
                                            <>
                                                {
                                                    (plans.activePlanId !== null || isAdmin) && (
                                                        <div className={"section"}>
                                                            <CreditPurchaseList creditPurchases={plans.creditPurchases}
                                                                                testMode={isTestMode}/>
                                                        </div>
                                                    )
                                                }
                                                <div className="section">
                                                    <div className={"container"}>
                                                        <AnimatedButton
                                                            onClick={() => window.location.href = `/${prefix}billing`}>
                                                            Payment History
                                                        </AnimatedButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    <HowDoCreditsWorkSection/>
                                </div>
                            </>
                        )
                    }
                }
            </LoaderWithFetch>
        </div>
    )
}

function PlanUserData(props: { plans: Plans }) {
    const plans = props.plans
    return (
        <>
            <div className="container-sm simple-grid">
                <div className="style-section-heading">
                    <div className="w-layout-grid row-md items-center items-justify-center">
                        <h4 className="font-title">Available Credits</h4>
                    </div>
                </div>
                <div className="text-lg _52ch">{plans.credits}</div>
            </div>
            {
                plans.activePlanId != null && !plans.cancelled && (
                    <>
                        <div className="container-sm simple-grid">
                            <div className="style-section-heading">
                                <div
                                    className="w-layout-grid row-md items-center items-justify-center">
                                    <h4 className="font-title">Days until plan renewal</h4>
                                </div>
                            </div>
                            <div className="text-lg _52ch">{plans.daysTillPlanRenewal}</div>
                        </div>
                        <div className="container-sm simple-grid">
                            <div className="style-section-heading">
                                <div
                                    className="w-layout-grid row-md items-center items-justify-center">
                                    <h4 className="font-title">Plan renews on the</h4>
                                </div>
                            </div>
                            <div className="text-lg _52ch">{plans.planRenewalDay}</div>
                        </div>
                    </>
                )
            }
            {
                plans.cancelled && (
                    <>
                        <div className="container-sm simple-grid">
                            <div className="style-section-heading">
                                <div
                                    className="w-layout-grid row-md items-center items-justify-center">
                                    <h4 className="font-title">The plan has been cancelled</h4>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

function translateActionName(plan: Plan, cancelled: boolean) {
    let renewPrefix = cancelled ? "Renew & " : "";
    if (plan.type === BYOK_BUSINESS) {
        switch (plan.actionType) {
            case ActionType.upgrade:
            case ActionType.downgrade:
                return renewPrefix + "Select"
        }
    }
    switch (plan.actionType) {
        case ActionType.new:
        case ActionType.login:
            return "Subscribe"
        case ActionType.renew:
            return "Renew"
        case ActionType.cancel:
            return "Cancel"
        case ActionType.upgrade:
            return renewPrefix + "Upgrade"
        case ActionType.downgrade:
            return renewPrefix + "Downgrade"
        default:
            return null
    }
}

function PlanEntry(props: { planData: PlanData, cancelled: boolean, testMode: boolean }) {
    const plan = props.planData.plan
    const actionName = translateActionName(plan, props.cancelled);
    const navigate = useNavigate()
    const context = useContext(PageContext)

    const executeAction = () => {
        if (plan.actionType === ActionType.login) {
            context.handleError("not_logged_in", "/plans")
            return
        }
        const checkoutPlanState: CheckoutPlanState = {
            planId: plan.id,
            testMode: props.testMode,
            actionType: plan.actionType
        }
        navigate(`/checkoutPlan`, {state: checkoutPlanState})
    }
    const priceSection = (
        <>
            <div className="text-xs">$</div>
            <h3
                className="title-xs">{plan.price / 100}</h3>
            <div className="text-xs">USD</div>
            {
                plan.price > 0 && (
                    <div className="text-xs">/ month</div>
                )
            }
        </>
    )
    const descriptionSection = (
        <>
            {plan.description && (
                <div className="bonus-tag">{plan.description.replace(/(<([^>]+)>)/gi, "")}</div>
            )}
        </>
    )
    const actionSection = (
        <>
            {
                actionName && (
                    <AnimatedButton onClick={executeAction}>
                        {actionName}
                    </AnimatedButton>
                )
            }
        </>
    )
    const checkboxSections = [
        (
            <div className="text-sm">
                {plan.credits}
                {
                    props.planData.creditsElement ?? (
                        <>Credits<CreditInfo/> per month</>
                    )
                }
            </div>
        ),
        (
            <div className="text-sm">
                Leftover Credits transfer to the next month
            </div>
        ),
    ]
    return (
        <PurchaseEntryBase name={`${plan.name} Plan`} priceSection={priceSection}
                           descriptionSection={descriptionSection}
                           actionSection={actionSection} checkboxSections={checkboxSections}/>
    )
}

function PurchaseEntryBase(props: {
    name: string,
    priceSection: React.JSX.Element,
    descriptionSection: React.JSX.Element,
    actionSection: React.JSX.Element,
    checkboxSections: React.JSX.Element[]
}) {

    return (
        <div className="item-team-pricing rounded">
            <div className="header-team-pricing">
                <div className="margin-b-md">
                    <div className="w-layout-grid column-sm content-centered">
                        <div>
                            <h5 className="font-title">{props.name}</h5>
                        </div>
                        <div className="w-layout-grid row-xs items-center items-justify-center">
                            {props.priceSection}
                        </div>
                        {props.descriptionSection}
                    </div>
                    <div className="w-layout-grid row-xs items-center items-justify-center margin-md">
                        {props.actionSection}
                    </div>
                </div>
            </div>
            <div className="description-team-pricing">
                <div className="w-layout-grid list-plan-pricing">
                    {
                        props.checkboxSections.map((section, index) => (
                            <div key={index} className="w-layout-grid row-xs">
                                <div className="material-icons-round">
                                    <div className="text-sm">check</div>
                                </div>
                                {section}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

function CreditPurchaseEntry(props: { creditPurchaseData: CreditPurchaseData, testMode: boolean }) {
    const creditPurchase = props.creditPurchaseData.creditPurchase
    const navigate = useNavigate()

    const executeAction = () => {
        navigate(`/checkoutCreditPurchase`, {state: {creditPurchaseId: creditPurchase.id, testMode: props.testMode}})
    }
    const priceSection = (
        <>
            <div className="text-xs">$</div>
            <h3
                className="title-xs">{creditPurchase.price / 100}</h3>
            <div className="text-xs">USD</div>
        </>
    )
    const descriptionSection = (
        <>
            {creditPurchase.description && (
                <div className="bonus-tag">{creditPurchase.description.replace(/(<([^>]+)>)/gi, "")}</div>
            )}
        </>
    )
    const actionSection = (
        <AnimatedButton onClick={executeAction}>
            Buy
        </AnimatedButton>
    )
    const checkboxSections = [
        (
            <div className="text-sm">
                {creditPurchase.credits} Credits
            </div>
        ),
        (
            <div className="text-sm">
                Leftover Credits transfer to the next month
            </div>
        ),
    ]
    return (
        <PurchaseEntryBase name={creditPurchase.name} priceSection={priceSection}
                           descriptionSection={descriptionSection}
                           actionSection={actionSection} checkboxSections={checkboxSections}/>
    )
}

function FreeTrialPlanEntry() {

    const priceSection = (
        <>
            <div className="text-xs">$</div>
            <h3
                className="title-xs">0</h3>
            <div className="text-xs">USD</div>
        </>
    )

    const checkboxSections = [
        (
            <div className="text-sm">
                300 Credits on signup
            </div>
        ),
        (
            <div className="text-sm">
                Leftover Credits transfer to the next month
            </div>
        ),
    ]

    return (
        <PurchaseEntryBase
            name="Free Trial"
            priceSection={priceSection}
            descriptionSection={<></>}
            actionSection={(<></>)}
            checkboxSections={checkboxSections}/>
    )
}

function BYOKPersonalPlanEntry() {
    const priceSection = (
        <>
            <div className="text-xs">$</div>
            <h3
                className="title-xs">0</h3>
            <div className="text-xs">USD</div>
        </>
    )

    const checkboxSections = [
        (
            <div className="text-sm">
                Bring your own OpenAI Key
            </div>
        ),
        (
            <div className="text-sm">
                Free for personal projects
            </div>
        ),
    ]

    const navigate = useNavigate()
    const context = useContext(PageContext)

    const executeAction = () => {
        if (context.loggedIn) {
            navigate("/profile", {state: {scrollTo: {targetId: "set-api-key", targetPath: "/profile"}}})
        } else {
            context.handleError("not_logged_in", "/profile#set-api-key")
        }
    }

    const actionSection = (
        <AnimatedButton onClick={executeAction}>
            Set Key
        </AnimatedButton>
    )

    return (
        <PurchaseEntryBase
            name="BYOK Personal"
            priceSection={priceSection}
            descriptionSection={<></>}
            actionSection={(actionSection)}
            checkboxSections={checkboxSections}/>
    )
}

function BYOKBusinessPlanEntry(props: { plan: Plan, testMode: boolean, cancelled: boolean }) {
    const context = useContext(PageContext)
    const navigate = useNavigate()
    const plan = props.plan
    const actionName = translateActionName(plan, props.cancelled);

    const executeAction = () => {
        if (plan.actionType === ActionType.login) {
            context.handleError("not_logged_in", "/plans")
            return
        }
        const checkoutPlanState: CheckoutPlanState = {
            planId: plan.id,
            testMode: props.testMode,
            actionType: plan.actionType
        }
        navigate(`/checkoutPlan`, {state: checkoutPlanState})
    }
    const priceSection = (
        <>
            <div className="text-xs">$</div>
            <h3
                className="title-xs">{plan.price / 100}</h3>
            <div className="text-xs">USD</div>
            {
                plan.price > 0 && (
                    <div className="text-xs">/ month</div>
                )
            }
        </>
    )
    const descriptionSection = (
        <>
            {plan.description && (
                <div className="bonus-tag">{plan.description.replace(/(<([^>]+)>)/gi, "")}</div>
            )}
        </>
    )
    const actionSection = (
        <>
            {
                actionName && (
                    <AnimatedButton onClick={executeAction}>
                        {actionName}
                    </AnimatedButton>
                )
            }
        </>
    )
    const checkboxSections = [
        (
            <div className="text-sm">
                Bring your own OpenAI Key
            </div>
        ),
        (
            <div className="text-sm">
                For commercial projects
            </div>
        ),
    ]

    return (
        <PurchaseEntryBase name={`${plan.name} Plan`} priceSection={priceSection}
                           descriptionSection={descriptionSection}
                           actionSection={actionSection} checkboxSections={checkboxSections}/>
    )
}

function EnterprisePlanEntry() {
    const navigate = useNavigate()
    const executeAction = () => {
        navigate(`/contact`)
    }

    const priceSection = (
        <>
            <h3 className="title-xs">-</h3>
        </>
    )

    const checkboxSections = [
        (
            <div className="text-sm">
                Contact us for custom pricing
            </div>
        ),
        (
            <div className="text-sm">
                Custom installation on-site or in the cloud for added code privacy
            </div>
        ),
        (
            <div className="text-sm">
                Priority Support
            </div>
        ),
    ]

    const actionSection = (
        <>
            <AnimatedButton onClick={executeAction}>
                Contact Us
            </AnimatedButton>
        </>
    )

    return (
        <PurchaseEntryBase
            name="Enterprise Plan"
            priceSection={priceSection}
            descriptionSection={<>For organizations</>}
            actionSection={actionSection}
            checkboxSections={checkboxSections}/>
    )
}

function PlanList(props: { plans: Plan[], cancelled: boolean, testMode: boolean }) {

    const normalPlans = props.plans.filter((plan) => {
        return plan.type !== BYOK_BUSINESS
    })
    const byokBusinessPlan = props.plans.find((plan) => {
        return plan.type === BYOK_BUSINESS
    })

    return (
        <section className="section padding-bottom">
            <div className="container-xl">
                <div className="w-layout-grid team-pricing" style={{display: 'grid', gridAutoRows: '1fr'}}>
                    <FreeTrialPlanEntry/>
                    {
                        normalPlans.map(plan => {
                            return (
                                <PlanEntry planData={{
                                    plan: plan
                                }} key={plan.id} cancelled={props.cancelled}
                                           testMode={props.testMode}/>
                            )
                        })
                    }
                    <BYOKPersonalPlanEntry/>
                    {
                        byokBusinessPlan && (
                            <BYOKBusinessPlanEntry plan={byokBusinessPlan} cancelled={props.cancelled}
                                                   testMode={props.testMode}/>
                        )
                    }
                    <EnterprisePlanEntry/>
                </div>
            </div>
        </section>
    )
}

function CreditPurchaseList(props: { creditPurchases: CreditPurchase[], testMode: boolean }) {
    return (
        <section className="section padding-bottom">
            <div className="container-xl">
                <div className="w-layout-grid team-pricing" style={{display: 'grid', gridAutoRows: '1fr'}}>
                    {
                        props.creditPurchases.map(creditPurchase => {
                            const planData: CreditPurchaseData = {
                                creditPurchase: creditPurchase
                            }
                            return (
                                <CreditPurchaseEntry creditPurchaseData={planData} testMode={props.testMode}
                                                     key={creditPurchase.id}/>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

function ModelSection(props: { name: string, price: string }) {
    return <>
        <div className="line-static"></div>
        <div className="w-layout-grid pricing-row">
            <div className="pricing-cell-1st">
                <div>{props.name}</div>
            </div>
            <div className="pricing-cell">
                <div>{props.price}</div>
            </div>
        </div>
    </>;
}

export function HowDoCreditsWorkSection() {
    return (
        <div className="container-lg" id="credits-explanation">
            <div className="max-width-xl">
                <div className="margin-bottom margin-xl">
                    <div className="w-layout-grid caption-holder">
                        <div className="line-holder">
                            <div
                                className="line-animated"></div>
                        </div>
                    </div>
                </div>
                <div className="w-layout-grid row-md">
                    <div>
                        <div className="margin-bottom margin-base">
                            <h2 className="title-sm _20ch">How do credits work? What are they for?</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-lg _48ch">Credits are the currency used on Codebuddy to make requests to
                different AI models.<br/> Each time you ask Codebuddy to do something, it costs a certain number
                of credits <br/>depending on the model you're using for the main portion of the
                request.<br/></div>
            <div className="pricing-table">
                <ModelSection name="GPT 4" price="16 Credits / request"/>
                <ModelSection name="GPT 4 Turbo" price="14 Credits / request"/>
                <ModelSection name="GPT 4 Omni" price="8 Credits / request"/>
                <ModelSection name="GPT 4 Omni Mini" price="Free"/>
                <ModelSection name="OpenAI o1" price="35 Credit / request"/>
                <ModelSection name="OpenAI o1 mini" price="10 Credit / request"/>
                <ModelSection name="Claude 3.5 Sonnet" price="5 Credit / request"/>
                <ModelSection name="Claude 3 Opus" price="22 Credit / request"/>
                <ModelSection name="Claude 3 Haiku" price="Free"/>
                <ModelSection name="Gemini 1.5 Pro" price="8 Credit / request"/>
            </div>
        </div>
    )
}

export function CreditInfo() {
    const jumpToCreditsExplanation = () => {
        scrollToId("credits-explanation")
    }
    return (
        <span onClick={jumpToCreditsExplanation} className={"cursor-pointer"}>
            <i className={"fab fa-circle-info"}/>
        </span>
    )
}